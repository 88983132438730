<div (swipe)="onSwipe($event)" class="player-card cursor-pointer flex-row gap-0">
  <div
    class="player-card-container card-container flex-row align-center"
    (click)="redirectToPosition(player.playerId!)"
  >
    <div mat-card-avatar class="user-avatar-rectangle">
      @if (player.photoUrl) {
        <img
          class="image"
          loading="lazy"
          [src]="player.photoUrl"
          alt="player photo"
          aria-label="A photo of player"
          i18n-alt
          i18n-aria-label
        />
      } @else {
        <div class="initials">
          {{ player.initial }}
        </div>
      }
    </div>
    <div class="user-info flex-3">
      @if (player.isCustomUser) {
        <p [ngClass]="{ username: true, 'fs-15': displayButtons[index] === 'revert' }" i18n>
          Added By &#64;{{ player.addedByUser }}
        </p>
        <p [ngClass]="{ fullname: true, 'fs-12': displayButtons[index] === 'revert' }">
          {{ player.firstName }} {{ player.lastName }}
        </p>
      } @else {
        <p [ngClass]="{ username: true }">&#64;{{ player.firstName }}{{ player.lastName }}</p>
        <p class="fullname info-text">{{ player.firstName }} {{ player.lastName }}</p>
      }
    </div>
    @if (player.positions?.length! > 0) {
      <div class="user-position flex-3">
        <div class="user-position-inner flex-row end align-center gap-10">
          @for (position of player.positions; track position.id) {
            <div [ngClass]="['position-' + position?.color, 'position-default']">
              <span [ngClass]="{ 'position-text': true }">{{ position?.title }}</span>
            </div>
          }
        </div>
      </div>
    }
  </div>
  <!-- [style.display]="displayButtons[index]" -->
  @if(isOwner) {
    <button 
    class="del-btn-desktop delete-btn-background"
    (click)="removeUserFromEvent()"
    aria-label="Click here to remove user from event"
    i18n-aria-label>
    <mat-icon class="va-middle" svgIcon="delete"></mat-icon>
  </button>
  }
  <div>
    <button
      class="delete-btn-background"
      [ngClass]="{
        display: displayButtons[index] === 'revert',
        delBtnMediaQuery: isOwner
      }"
      (click)="removeUserFromEvent()"
      aria-label="Click here to remove user from event"
      i18n-aria-label
    >
      <mat-icon class="va-middle" svgIcon="delete"></mat-icon>
    </button>
  </div>
</div>
