import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { getSnackBarConfig } from 'src/app/utils/material.utils';
import { User } from 'src/app/services/hobbyts.service';

@Component({
  selector: 'hb-player-card',
  templateUrl: './player-card.component.html',
  styleUrls: ['./player-card.component.scss'],
  standalone: true,
  imports: [MatCardModule, NgClass, MatIconModule]
})
export class PlayerCardComponent implements OnInit {
  @Input() public player!: any;
  @Input() public index!: number;
  @Input() public isOwner: boolean;
  @Output() private onPlayerDelete = new EventEmitter<User>();

  public eventId!: string | null;
  public displayButtons: any[] = [];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    protected sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    this.eventId = this.activeRoute.snapshot.paramMap.get('id');

    if (this.player) {
      this.displayButtons.push('none');

      if (this.player.photoUrl) {
        this.player = {
          ...this.player,
          photoUrl: this.sanitizer.bypassSecurityTrustUrl(this.player.photoUrl) as any
        };
      }
    }
  }

  public redirectToPosition(playerId: string): void {
    this.router.navigate(['profile', playerId]);
  }

  public openSnackBar(message: string, buttonTxt: string): void {
    this.snackBar.open(message, buttonTxt, getSnackBarConfig());
  }

  public onSwipe(evt: any): void {
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'left' && this.isOwner) {
      this.displayButtons[this.index] = 'revert';
    }
    if (x === 'right' && this.isOwner) {
      this.displayButtons[this.index] = 'none';
    }
  }

  public removeUserFromEvent(): void {
    this.displayButtons.splice(this.index, 1);
    this.onPlayerDelete.emit(this.player);
  }
}
